import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Inner,
  WrapYears,
  ItemYears,
  Title,
  Text,
  Size,
  WrapControlTabs,
  SwitchButtons,
} from "./otcheti-styled";

import downloadIcon from "./download-icon.png";
import { Pisima } from "./pisima";

export const Otcheti = () => {
  const [tab, setTab] = useState("otcheti");
  const [istorii, setIstorii] = useState([]);

  useEffect(() => {
    fetch(
      "https://domdliamamy.ru/donation/wp-json/wp/v2/pages/?slug=istoria-pogertvovanii",
    )
      .then(function (data) {
        data
          .json()
          .then((value) => {
            setIstorii(value);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch(function (error) {
        return error;
      });
  }, []);

  return (
    <Wrapper>
      <Inner>
        <h2>
          {tab === "otcheti" && "Результаты деятельности"}
          {tab === "buhgalteria" && "Бухгалтерская отчетность"}
          {tab === "istoria" && "Поступления пожертвований"}
          {tab === "pisima" && "Благодарственные письма"}
          {tab === "uchredit" && "Учредительные документы"}
        </h2>
        <WrapControlTabs>
          <SwitchButtons
            className={tab === "otcheti" ? "active" : "notactiv"}
            onClick={() => setTab("otcheti")}
          >
            Результаты деятельности
          </SwitchButtons>
          <SwitchButtons
            className={tab === "buhgalteria" ? "active" : "notactiv"}
            onClick={() => setTab("buhgalteria")}
          >
            Бухгалтерская отчетность
          </SwitchButtons>
          <SwitchButtons
            className={tab === "uchredit" ? "active" : "notactiv"}
            onClick={() => setTab("uchredit")}
          >
            Учредительные документы
          </SwitchButtons>
          <SwitchButtons
            className={tab === "pisima" ? "active" : "notactiv"}
            onClick={() => setTab("pisima")}
          >
            Благодарственные письма
          </SwitchButtons>
          <SwitchButtons
            className={tab === "istoria" ? "active" : "notactiv"}
            onClick={() => setTab("istoria")}
          >
            Поступления пожертвований
          </SwitchButtons>
        </WrapControlTabs>

        {tab === "otcheti" && (
          <>
            <WrapYears>
              <Title>2023</Title>

              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/11/дом-для-мамы-отчет-2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Публичный годовой отчет за 2023 год
                  </Text>
                  <Size>PDF, 73mb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/II-квартал-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за II квартал 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/I-квартал-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за I квартал 2023 года
                  </Text>
                  <Size>PDF, 30kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Август-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Август 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Июль-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Июль 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Июнь-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Июнь 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Май-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Май 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Апрель-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Апрель 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Март-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Март 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Февраль-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Февраль 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Январь-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Январь 2023 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
            </WrapYears>

            <WrapYears>
              <Title>2022</Title>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/11/Годовой-отчет-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Публичный годовой отчет за 2022 г.</Text>
                  <Size>PDF, 3.26 мб</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/2022-год.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о работе АНО "Дом для мамы" за 2022 год</Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/IV-квартал-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за IV квартал 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/III-квартал-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за III квартал 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/II-квартал-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за II квартал 2022 года
                  </Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/I-квартал-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за I квартал 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Декабрь-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Декабрь 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Ноябрь-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Ноябрь 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Октябрь-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Октябрь 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Сентябрь-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Сентябрь 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Август-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Август 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Июль-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Июль 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Июнь-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Июнь 2022 года
                  </Text>
                  <Size>PDF, 29kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Май-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Май 2022 года
                  </Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Апрель-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Апрель 2022 года
                  </Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Март-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Март 2022 года
                  </Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Февраль-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Февраль 2022 года
                  </Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/Январь-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о работе АНО "Дом для мамы" за Январь 2022 года
                  </Text>
                  <Size>PDF, 25kb</Size>
                </a>
              </ItemYears>

              <Title>2021</Title>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/10/DDM-presentation-2021-с-фин.отчетностью.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Публичный годовой отчет за 2021 г.</Text>
                  <Size>PDF, 12.8 мб</Size>
                </a>
              </ItemYears>
            </WrapYears>
          </>
        )}

        {tab === "pisima" && (
          <>
            <Pisima />
          </>
        )}
        {tab === "istoria" && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: istorii[0]?.content?.rendered,
              }}
            />
          </>
        )}

        {tab === "uchredit" && (
          <>
            <br />
            <br />
            <ItemYears>
              <a
                href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/svid_inn.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={downloadIcon} alt="ДомДляМамы" />
                <Text>Свидетельство ИНН</Text>
                <Size>PDF 3.9 мб</Size>
              </a>
            </ItemYears>

            <ItemYears>
              <a
                href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/svid_ogrn.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={downloadIcon} alt="ДомДляМамы" />
                <Text>Свидетельство ОГРН</Text>
                <Size>PDF 3.1 мб</Size>
              </a>
            </ItemYears>

            <ItemYears>
              <a
                href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/ustav.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={downloadIcon} alt="ДомДляМамы" />
                <Text>Устав</Text>
                <Size>PDF 1.5 мб</Size>
              </a>
            </ItemYears>

            <ItemYears>
              <a
                href="https://domdliamamy.ru/donation/wp-content/uploads/2023/09/prikaz_o_vstuplenii.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={downloadIcon} alt="ДомДляМамы" />
                <Text>Приказ о вступлении в должность директора</Text>
                <Size>PDF 750 кб</Size>
              </a>
            </ItemYears>
          </>
        )}

        {tab === "buhgalteria" && (
          <>

            <WrapYears>
              <Title>2023</Title>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-благотворительной-организации-2023_0001.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о деятельности благотворительной организации за 2023</Text>
                  <Size>PDF, 5.1 мб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-целях-расходования-некоммерческой-организацией-денежных-средств-2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о целях расходования НКО денежных средств и использования иного имущества за 2023
                  </Text>
                  <Size>PDF, 92 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-НКО-2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчет о деятельности НКО за 2023
                  </Text>
                  <Size>PDF, 98 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/АНО-ДОМ-ДЛЯ-МАМЫ-9709050791-770901001-Упрощенная-бухгалтерская-финансовая-отчетность-за-2023-год.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Бухгалтерская отчетность за 2023
                  </Text>
                  <Size>PDF, 280 кб</Size>
                </a>
              </ItemYears>
            </WrapYears>

            <WrapYears>
              <Title>2022</Title>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/Бухгалтерская_Финансовая_отчетность_2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Бухгалтерская финансовая отчетность за 2022</Text>
                  <Size>PDF, 1.7mb</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/Квитанция_о_приеме_налог_декларации_2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Квитанция о приеме налоговой декларации за 2022</Text>
                  <Size>PDF, 253kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-благотворительной-организации-2022_0001.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о деятельности благотворительной организации</Text>
                  <Size>PDF, 253kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-целях-расходования-некоммерческой-организацией-денежных-средств-2022.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о целях расходования НКО денежных средств и использования иного имущества</Text>
                  <Size>PDF, 92kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-НКО-2022.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о деятельности НКО</Text>
                  <Size>PDF, 94kb</Size>
                </a>
              </ItemYears>


            </WrapYears>

            <WrapYears>
              <Title>2021</Title>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/Бухгалтерская_Финансовая_отчетность_2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Бухгалтерская финансовая отчетность за 2021 г.</Text>
                  <Size>PDF, 1.6mb</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/Квитанция_о_приеме_налог_декларации_2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Квитанция о приеме налоговой декларации за 2021 г.
                  </Text>
                  <Size>PDF, 360 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2022/04/oh00012022.xls"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчёт о деятельности НКО за 2021 г.</Text>
                  <Size>PDF, 175 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2024/11/Отчет_о_целях_расходования_некоммерческой_организацией_денежных.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчёт о целях расходования НКО денежных средств и
                    использования иного имущества за 2021 г.
                  </Text>
                  <Size>PDF, 92 кб</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/11/Отчет_о_деятельности_благотворительной_организации_2021_0001.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о деятельности благотворительной организации</Text>
                  <Size>PDF 2.8 мб</Size>
                </a>
              </ItemYears>
            </WrapYears>

            <WrapYears>
              <Title>2020</Title>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/Бухгалтерская_Финансовая_отчетность_2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Бухгалтерская финансовая отчетность за 2020 г.</Text>
                  <Size>PDF, 162kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/06/Квитанция-о-приеме-Бухотчет2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Квитанция о приеме налоговой декларации за 2020 г.
                  </Text>
                  <Size>PDF, 52kb</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/ОН0001-2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчёт о деятельности НКО за 2020 г.</Text>
                  <Size>PDF, 92 кб</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/ОН0002-2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчёт о целях расходования НКО денежных средств и
                    использования иного имущества за 2020 г.
                  </Text>
                  <Size>PDF, 84 кб</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2023/04/Презентация_ДДМ_за_2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Презентация о работе Центра</Text>
                  <Size>PDF 7.6 мб</Size>
                </a>
              </ItemYears>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-благотворительной-организации-2020_0001.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчет о деятельности благотворительной организации</Text>
                  <Size>PDF 2.8 мб</Size>
                </a>
              </ItemYears>



            </WrapYears>

            <WrapYears>
              <Title>2019</Title>
              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/АНО-ДОМ-ДЛЯ-МАМЫ-9709050791-770901001-Упрощенная-бухгалтерская-финансовая-отчетность-за-2019-год-1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Бухгалтерская финансовая отчетность за 2019 г.</Text>
                  <Size>PDF, 276 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-НКО-2019.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>Отчёт о деятельности НКО за 2019 г.</Text>
                  <Size>PDF, 122 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                  href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-целях-расходования-НКО-денежных-средств-2019-1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчёт о целях расходования НКО денежных средств и
                    использования иного имущества за 2019 г.
                  </Text>
                  <Size>PDF, 81 кб</Size>
                </a>
              </ItemYears>

              <ItemYears>
                <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2024/10/Отчет-о-деятельности-благотворительной-организации-2019-1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <img src={downloadIcon} alt="ДомДляМамы" />
                  <Text>
                    Отчёт о деятельности благотворительной организации за 2019 год
                  </Text>
                  <Size>PDF, 2.7 мб</Size>
                </a>
              </ItemYears>

            </WrapYears>
          </>
        )}
      </Inner>
    </Wrapper>
  );
};
